.select-audio-modal {
  --actions-height: 48px;
  z-index: 1002;

  &__content {
    padding: var(--nd-size-2x);
    padding-bottom: var(--actions-height);

    >*:not(:last-child) {
      margin-bottom: var(--nd-size-2x);
    }
  }

  &__loading {
    height: initial !important;
  }
  
  &__actions {
    display: flex;
    height: var(--actions-height);
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    padding: var(--nd-size-base);
    background-color: var(--background-light-primary);
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.12);
    border-radius: var(--nd-border-radius);
    z-index: 2;

    >*:not(:last-child) {
      margin-right: var(--nd-size-base);
    }

    > * {
      flex-grow: 1;
    }
  }
}