.nd-datepicker {
  &__custom-input {
    display: flex;
    width: 100%;
    align-items: center;
    border: 1px solid transparent;
    border-radius: var(--nd-border-radius-large);
    background-color: var(--day-background-minor);
    padding: calc(var(--nd-size-2x) - 1px); // Minus border
    padding-right: var(--nd-size-3x);
    padding-left: var(--nd-size-3x);
    gap: var(--nd-size-2x);
    box-sizing: border-box;

    &_clearable {
      padding-right: var(--nd-size-9x);
    }

    &:hover {
      border-color: var(--focus-color);
      box-shadow: 0 0 1px 1px var(--focus-color);
    }
    &:focus {
      border-color: var(--focus-color);
      box-shadow: 0 0 1px 1px var(--focus-color);
    }
  }

  &__input-text {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__custom-input-tooltip {
    padding: var(--nd-size-2x);
    background-color: var(--text-icons-light-primary);
  }

  &_fluid {
    width: 100%;
  }

  &_disabled {
    opacity: .5;
    pointer-events: none;
    cursor:not-allowed;
  }

  &__time-input-wrapper {
    width: 100%;
    border-top: 1px solid var(--line);
    padding-top: var(--nd-size-2x);
    grid-area: time;

    > * {
      max-width: 180px;
    }
  }

  &__date-input-wrapper {
    width: 100%;
    border-top: 1px solid var(--line);
    padding-top: var(--nd-size-2x);
    grid-area: date;
  }

  &__helper-buttons-wrapper {
    grid-area: helpers;
  }
}

.nd-datepicker-header {
  position: relative;
  padding: 0 60px var(--nd-size-2x);
  min-height: 56px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--line);
  margin: 0 var(--nd-size-3x);

  &__month-and-year-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title-month {
    font: var(--body-2);
    text-transform: capitalize;
  }

  &__title-year {
    font: var(--caption-1);
  }

  &__year {
    font: var(--caption-1);
  }

  &__button-prev-month,
  &__button-next-month {
    position: absolute;
    top: calc(50% - var(--nd-size-2x)/2);
    transform: translateY(-50%);
    border-radius: 50%;
  }

  &__button-prev-month {
    left: calc(-1 * var(--nd-size-3x));
  }

  &__button-next-month {
    right: calc(-1 * var(--nd-size-3x));
  }
}

.react-datepicker {
  --calendar-day-size: 44px;
  --calendar-day-margin: var(--nd-size-0_5x);
  --calendar-day-name-size: 40px;
  --help-buttons-height: 40px;
  --today-color: #DC9212;
  --active-color: #FCE000;
  --clear-button: #7e7b7b;

  border-radius: var(--nd-border-radius-large);
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: var(--nd-size-2x);
  box-sizing: border-box;
  display: grid;
  grid-template-areas: 
    'first-mo second-mo'
    'helpers helpers'
    'time time';
  gap: var(--nd-size-2x);

  &__tab-loop {
    margin-right: 0 !important;
  }

  &.with-help-buttons {
    padding-bottom: calc(var(--help-buttons-height) + var(--nd-size-2x));
  }

  &__header {
    background-color: var(--background-light-primary);
    border-bottom: none;
    padding-top: 0;
  }

  &__navigation {
    top: calc(var(--nd-size-2x) + 20px);
    transform: translateY(-50%);
  }

  &__current-month,
  &__header--time {
    line-height: 40px;
    text-transform: capitalize;
  }

  &__month {
    margin: 0;
  }

  &__month-container {
    &:first-of-type {
      grid-area: first-mo;
    }
    &:not(:first-of-type) {
      grid-area: second-mo;
    }
  }

  &__day,
  &__day-name {
    margin: var(--calendar-day-margin) 0;
    width: var(--calendar-day-size);
    font: var(--body-2);
    line-height: var(--calendar-day-size);
  }

  &__day-name,
  &__day {
    margin: var(--calendar-day-margin);
  }

  &__day-name {
    font: var(--caption-1);
    color: var(--text-minor)
  }

  &__day {
    position: relative;
    border-radius: 0;
    color: var(--text-icons-light-primary);
    z-index: 1;

    &,
    &:hover,
    &:focus {
      border-radius: 14px;
    }

    &:not(&--disabled):hover {
      background-color: rgba(250, 240, 85, 0.5);
    }
  }

  &__in-selecting-range,
  &__day--in-range,
  &__day--in-selecting-range {
    background-color: initial;
    border-radius: 14px;
    background-color: var(--active-color) !important;
  }

  &__day--range-start,
  &__day--range-end,
  &__day--selected {
    background-color: var(--active-color) !important;
    border-radius: 14px;
  }
  &__day--outside-month {
    opacity: .2;
  }

  &__day--keyboard-selected {
    background-color: initial;
  }

  &__day--today {
    color: var(--today-color);
  }

  &__day--disabled {
    color: var(--text-icons-light-primary);
    opacity: 0.2;
  }

  &__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;

    &--selected {
      background-color: var(--active-color) !important;
      color: var(--text-icons-light-primary) !important;
    }

    &:hover {
      background-color: rgba(250, 240, 85, 0.5) !important;
    }
  }

  &__close-icon {
    display: none;
  }

  &__close-icon::after {
    background-color: var(--clear-button);
  }
}

.react-datepicker-wrapper {
  display: flex;
}
