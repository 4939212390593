.modal-wrap {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.05%);

  &.nd-modal {
    border-radius: var(--nd-border-radius-extra-large);
    box-shadow: var(--nd-base-shadow);

    .modal {
      min-width: 450px;
      min-height: auto;
      position: relative;

      .nd-modal__close-button + .modal-header,
      .nd-modal__close-button + .modal-body {
        padding-right: 44px;
      }

      .modal-header {
        border-top-right-radius: var(--nd-border-radius-extra-large);
        border-top-left-radius: var(--nd-border-radius-extra-large);
        font: var(--title-4);
        font-weight: var(--font-weight-bold);
        padding-top: var(--nd-size-4x);
        position: relative;
        display: flex;
        align-items: center;

        .nd-modal__close-button {
          top: var(--nd-size-4x);
        }
      }

      .nd-modal__close-button_outside {
        position: absolute;
        right: var(--nd-size-2x);
        top: var(--nd-size-2x);
      }

      .modal-footer {
        background: transparent;
        padding: var(--nd-size-4x);
        padding-top: var(--nd-size-2x);
        display: flex;
        align-items: center;
        gap: var(--nd-size-2x);

        .nd-button {
          flex-grow: 1;
        }
      }
    }
  }

  .modal {
    display: flex;
    flex-direction: column;
    min-height: 160px;
    min-width: 272px;
    max-width: 640px;
    white-space: pre-line;
    max-height: 90vh;

    .modal-header {
      display: flex;
      justify-content: space-between;
      background-color: var(--background-light-primary);
      padding: 24px 16px;
      padding-bottom: 0;
    }
    .modal-body {
      padding: var(--nd-size-2x) var(--nd-size-4x) var(--nd-size-2x) var(--nd-size-4x);
      flex-grow: 1;
      overflow: auto;
      word-break: break-word;
    }
    .modal-footer {
      background-color: var(--background-light-overflow);
      display: flex;
      justify-content: flex-end;
      padding: var(--nd-size-2x);
    }
  }

  &.without-title .modal-body {
    margin-top: var(--nd-size-2_5x);
    padding-right: var(--nd-size-10x);
  }
}
