.filters-list {
  display: grid;
  gap: var(--nd-size-2x);
  width: 100%;
  grid-template-columns: 1fr 1fr;

  > *:last-child:nth-last-child(odd) {
    grid-column: auto / span 2;
  }

  .sampling-filter-item:last-child:nth-last-child(odd) {
    grid-column: auto / span 2;
  }
  
  .sampling-filter-item:first-child:nth-last-child(even),
  .sampling-filter-item:first-child:nth-last-child(even) ~ .sampling-filter-item {
    grid-column: auto / span 1;
  }
  
}