.upload-file-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background-color: transparent;
  color: #324CF0;
  padding: 12px;
  width: 100%;

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid #324CF0;
    margin-right: 8px;

    &.hovered {
      border: 1px solid ;
    }
  }
}