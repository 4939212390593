.audio-item {
  width: 100%;
  
  &__audio-wrapper {
    padding-right: 8px;
  }

  &__wrapper {
    display: flex;
    grid-template-columns: 54px 1fr 40px;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: var(--nd-border-radius-large);
    gap: var(--nd-size-base);
    &.hovered {
      border-color: var(--line);
    }

    .audio-meta {
      min-width: 1px;
    }
  }

  &__edit-button,
  &__select-button {
    display: none;
  }
  &:hover &__edit-button,
  &:hover &__select-button {
    display: block;
  }
  &_select-button_selected {
    display: block;
  }

  &__actions {
    display: flex;
    align-items: center;

    *:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__edit-button {
    .icon-wrapper {
      color: var(--text-icons-light-secondary);
    }
  }
}