.nd-create-domain-modal {
  height: fit-content;

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-2x);
  }

  &__loading-row {
    min-height: 40px;
  }

  &__error {
    font: var(--caption-1);
    color: var(--accent-light-red);
    padding-left: var(--nd-size-4x);
  }
}