.select-multi-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: var(--nd-size-3x);
  box-sizing: border-box;
  background-color: var(--background-light-primary);

  &__list {
    --option-height: 56px;
    --scrollable-height: calc(var(--option-height) * 6);

    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-height: var(--scrollable-height);
    overflow-y: auto;

    &_with-select-all,
    &_with-operator {
      --scrollable-height: calc(var(--option-height) * 5);
    }

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #00000040;
      border-radius: 3px;
    }
   &::-webkit-scrollbar-track {
      background: #5C5A571A;
      border-radius: 3px;
    }
  }

  &__option {
    justify-content: flex-start;
    gap: var(--nd-size-2x);
    max-height: auto;
  }

  &__icon {
    display: none;
    margin-left: auto;
  }

  &__option:hover &__icon {
    display: flex;
  }

  &__no-data {
    text-align: center;
    padding: var(--nd-size-base) 0;
  }

  &__operator {
    padding: var(--nd-size-base) var(--nd-size-4x) var(--nd-size-2x);
    display: flex;
    flex-direction: column;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-break: break-all;

    span {
      text-align: left;
    }
  }

  &__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__delimiter {
    background-color: var(--day-line);
    height: 1px;
    width: 100%;
  }

  &__apply-button {
    margin-top: var(--nd-size-3x);
  }

  &__loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.125);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}