.sai-notifications-container {
  position: absolute;
  overflow: visible;
  z-index: 10003;

  transition: left .3s, right .3s;

  bottom: 24px;

  &_position_left {
    left: 74px;
  }
  &_position_right {
    right: 24px;
  }
}