.MuiTooltip-tooltip {
  color: unset !important;
  padding: unset !important;
  font-size: unset !important;
  max-width: unset !important;
  word-wrap: unset !important;
  font-family: unset !important;
  font-weight: unset !important;
  line-height: unset !important;
  border-radius: unset !important;
  background-color: unset !important;
}

.MuiTooltip-tooltipPlacementTop,
.MuiTooltip-tooltipPlacementBottom {
  margin: 4px 0 !important;

  @media (min-width: 600px) {
    margin: 4px 0 !important;
  }
}

.nd-popup-with-trigger {
  background-color: var(--background-light-primary);
  color: var(--text-icons-light-primary);
  border-radius: var(--nd-border-radius);
  padding: var(--nd-size-2x) var(--nd-size-2_5x);
  z-index: 1000;
  opacity: 1;
  white-space: nowrap;
  box-shadow: var(--nd-main-shadow);

  &_dark {
    background-color: var(--accent-light-dark);
    color: var(--text-icons-light-primary-inverted);
  }
}