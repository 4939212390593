@mixin actionButtons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-light-primary);
  box-shadow: var(--nd-popup-shadow);
  display: flex;
  padding: var(--nd-size-2x);
  border-top-right-radius: var(--nd-border-radius);
  border-top-left-radius: var(--nd-border-radius);

  > * {
    flex-grow: 1;
    flex-basis: 5%;
    &:not(:last-child) {
      margin-right: var(--nd-size-2x);
    }
  }
}

@mixin header {
  font: var(--title-4);
  font-weight: var(--font-weight-bold);
  color: var(--text-icons-light-primary);
  display: flex;
  align-items: center;
  gap: var(--nd-size-2x);
  margin: 0 0 var(--nd-size-8x);

  .nd-button {
    padding: var(--nd-size-2x);
  }
}

@mixin skeleton($w) {
  display: flex;
  height: var(--nd-size-5x);
  width: $w;
  background: linear-gradient(
    110deg,
    #ececec 8%,
    #f5f5f5 18%,
    #ececec 33%
  );
  border-radius: var(--nd-size-2x);
  background-size: 200% 100%;
  animation: blink 1.5s linear infinite;
  gap: 8px;

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
  }
  
  &::before {
    flex-grow: 2;
  }

  @keyframes blink {
    to {
      background-position-x: -200%;
    }
  }
}

@mixin delimiter() {
  content: '';
  position: absolute;
  bottom: 0;
  height: 1px;
  left: 16px;
  right: 16px;
  background: var(--line);

  @media (-webkit-min-device-pixel-ratio: 2) {
    transform: scaleY(0.5);
  }
}

@mixin overlay() {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--day-fog);
  cursor: pointer;
}

@mixin sideModalHeader {
  display: flex;
  gap: var(--nd-size-2x);
  align-items: center;
  font: var(--title-4);
  font-weight: var(--font-weight-bold);
}

@mixin hover($originalBgColor) {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), $originalBgColor;
}

@mixin pressed($originalBgColor) {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), $originalBgColor;
}

@mixin disabled {
  opacity: .5;
  pointer-events: none;
}

@mixin focus {
  outline: var(--focus-outline-border);
  outline-offset: -2px;
}

@mixin customScrollbar {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #00000040;
    border-radius: 3px;
  }
 &::-webkit-scrollbar-track {
    background: #5C5A571A;
    border-radius: 3px;
  }
}
