@import "../../mixins.scss";

.project-selector-menu-wrapper {
  position: absolute;
  left: var(--nd-sidebar-folded-width);
  top: 0;
  z-index: 2;
  background-color: var(--background-light-primary);
  width: var(--nd-sidebar-unfolded-width);
  border-radius: var(--nd-border-radius-large);
  box-shadow: var(--nd-base-shadow);
  overflow: hidden;
  direction: ltr;
  padding-top: var(--nd-size-2x);

  &__current-project {
    display: grid;
    width: 100%;
    grid-template-columns: 40px 1fr 24px;
    align-items: center;
    height: 48px;
    font: var(--body-2);
    background-color: var(--background-light-primary);
    border: none;
    gap: var(--nd-size-2x);
    text-align: left;
    cursor: pointer;

    &:hover {
      @include hover(var(--background-light-primary));
    }
  }

  &__project-icon {
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
  }

  .nd-custom-select {
    &__menu {
      position: initial;
      box-shadow: none;
      border-radius: 0;
      margin: 0;
    }
    &__control {
      width: calc(100% - var(--nd-size-4x));
      margin: var(--nd-size-2x) auto var(--nd-size-2x);
      background-color: rgba(92, 90, 87, 0.1);
      box-shadow: none !important;
      padding: var(--nd-size-4x);

      &:hover,
      &:focus {
        @include focus;
      }
    }
  }
}

.sidebar_expanded .project-selector-menu-wrapper {
  left: 0;
  top: 48px;
}