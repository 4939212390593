@font-face {
    font-family: "Yandex Sans";
    src: url("./assets/fonts/YandexSansDisplay-Regular.ttf");
}

h4 {
    margin: 0;
}

iframe {
    pointer-events: none;
}

:root {
    //
    // nd === new-design
    //
    --nd-size-base: 4px;
    --nd-size-0_5x: calc(var(--nd-size-base) * 0.5);
    --nd-size-1_5x: calc(var(--nd-size-base) * 1.5);
    --nd-size-2x: calc(var(--nd-size-base) * 2);
    --nd-size-2_5x: calc(var(--nd-size-base) * 2.5);
    --nd-size-3x: calc(var(--nd-size-base) * 3);
    --nd-size-3_5x: calc(var(--nd-size-base) * 3.5);
    --nd-size-4x: calc(var(--nd-size-base) * 4);
    --nd-size-5x: calc(var(--nd-size-base) * 5);
    --nd-size-6x: calc(var(--nd-size-base) * 6);
    --nd-size-7x: calc(var(--nd-size-base) * 7);
    --nd-size-8x: calc(var(--nd-size-base) * 8);
    --nd-size-9x: calc(var(--nd-size-base) * 9);
    --nd-size-10x: calc(var(--nd-size-base) * 10);
    --nd-size-11x: calc(var(--nd-size-base) * 11);
    --nd-size-12x: calc(var(--nd-size-base) * 12);

    --nd-paddings: var(--nd-size-2x) var(--nd-size-3x);

    --nd-border-radius-small: var(--nd-size-2x);
    --nd-border-radius: var(--nd-size-3x);
    --nd-border-radius-large: var(--nd-size-4x);
    --nd-border-radius-extra-large: var(--nd-size-6x);

    --nd-main-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);

    --nd-popup-shadow: 0px 4px 20px rgba(117, 115, 111, 0.2);
    --nd-popup-shadow-small: 0px 0px 4px rgba(117, 115, 111, 0.2);
    --nd-bottom-modal-shadow: 0px -4px 20px rgba(0, 0, 0, 0.12);

    --nd-sidebar-unfolded-width: 272px;
    --nd-sidebar-folded-width: 64px;

    --nd-base-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);

    // Backgrounds
    --background-light-overflow: #F3F3F2;
    --background-light-primary: #FFF;
    --background-light-secondary: #FFF;
    --background-light-tetriary: #FFF;
    --background-floating: #FFF;

    // Text and icons
    --text-icons-light-primary: #21201F;
    --text-icons-light-secondary: rgba(0, 0, 0, 0.66);
    --text-icons-light-tertiary: rgba(0, 0, 0, 0.45);
    --text-icons-light-disabled: rgba(0, 0, 0, 0.25);
    --text-icons-light-primary-inverted: #f6f6f6;
    --text-icons-light-secondary-inverted: rgba(255, 255, 255, 0.69);
    --text-minor: #9E9B98;

    // Forced colors
    --forced-black: #000;
    --forced-white: #FFF;

    // Fill
    --fill-light-30: rgba(0, 0, 0, 0.30);
    --fill-light-24: rgba(0, 0, 0, 0.24);
    --fill-light-18: rgba(0, 0, 0, 0.18);
    --fill-light-12: rgba(0, 0, 0, 0.12);
    --fill-light-6: rgba(0, 0, 0, 0.6);

    // Applied colors
    --applied-light-image-fill: rgba(0, 0, 0, 0.06);
    --applied-light-overlay: rgba(0, 0, 0, 0.40);
    --applied-light-hover: rgba(51, 51, 51, 0.05);
    --applied-light-stroke: rgba(51, 51, 51, 0.12);
    --applied-light-separator: rgba(0, 0, 0, 0.08);
    --applied-forced-media-transparent-bar: rgba(47, 47, 50, 0.95);
    --applied-link: #0060D0;
    --applied-link-hover: #62AAFD;

    // Accents
    --accent-light-red: #F33;
    --accent-light-yellow: #FC0;
    --accent-light-blue: #07F;
    --accent-light-green: #00B341;
    --accent-light-purple: #8533FF;
    --accent-light-orange: #FF5317;
    --accent-light-dark: #302F2D;

    // Chart colors (TODO move to chart component when it's ready)
    --chart-blue-light: #169CDC;
    --chart-purple: #8822D0;
    --chart-yellow: #FAC220;
    --chart-red: #D4412E;
    --chart-green: #029154;
    --chart-blue-dark: #1A00B7;
    --chart-pink: #FFADAD;
    --chart-sepia: #C7A95E;
    --chart-grey: #8A8784;

    // Typography
    --font-weight-bold: 700;
    --font-weight-medium: 500;
    --font-weight-regular: 400;

    --font-family: "Yandex Sans", sans-serif;

    --header: 3rem/2.875rem var(--font-family);

    --title-1: 2.5rem/2.625rem var(--font-family);
    --title-2: 2rem/2.1875rem var(--font-family);
    --title-3: 1.75rem/2.055rem var(--font-family);
    --title-4: 1.5rem/1.7675rem var(--font-family);

    --body-1: 1.25rem/1.4375rem var(--font-family);
    --body-2: 1rem/1.1875rem var(--font-family);

    --caption-1: 0.8125rem/0.9375rem var(--font-family);
    --caption-2: 0.6875rem/0.8125rem var(--font-family);


    // Inputs
    --focus-color: #21201F;
    --focus-outline-border: 2px solid var(--focus-color);

    --control-minor: rgba(92, 90, 87, 0.10);
    --control-contrast: var(--forced-white);
    --control-main: #FCE000;
    --line: rgba(138, 135, 132, 0.40);
    --error: rgba(250, 62, 44, 1);

    --placeholder-color: rgba(158, 155, 152, 1);

    --control-height-xs: 32px;
    --control-height-s: 40px;
    --control-height-m: 40px;
    --control-height-l: 56px;

    --control-border-radius-s: 10px;
    --control-border-radius-m: 13px;
    --control-border-radius-l: 16px;


    // Others
    --chrome-100: #E6ECF0;
    --day-line: hsla(30, 3%, 53%, 0.4);
    --warm-gray: #F1F0ED;
    --day-background-minor: #F5F4F2;
    --stab-bg-color: #F4F4F4;
    --day-fog: rgba(0,0,0, .5);
    --cold-gray: #EDF0F1;
}

.font-crossed {
    text-decoration: line-through;
}

.underlined {
    border-bottom: 1px dashed var(--forced-black);
    border-radius: 0;
}

.disabled {
    filter: opacity(50%);
}

html {
    overflow: hidden;
}
* {
    font-family: "Yandex Sans", sans-serif;
}
body {
    font: var(--body-2);
    margin: 0;
}

.body-1 {
    font: var(--body-1);
}

.body-2 {
    font: var(--body-2);
}

.caption-1 {
    font: var(--caption-1);
}

.caption-2 {
    font: var(--caption-2);
}

.title-1 {
    font: var(--title-1);
}

.title-2 {
    font: var(--title-2);
}

.title-3 {
    font: var(--title-3);
}

.title-4 {
    font: var(--title-4);
}

.font-bold {
    font-weight: var(--font-weight-bold);
}

.font-medium {
    font-weight: var(--font-weight-medium);
}

.font-regular {
    font-weight: var(--font-weight-regular);
}

.font-italic {
    font-style: italic;
}

.text-center {
    text-align: center;
}

.autosize {
    width: 100%;
    height: 100%;
}

.float {
    width: 100%;
}

.crop-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.no-scale-text {
    font-size: initial !important;
}

.touchable {
    cursor: pointer;
}

.hidden {
    display: none;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.row-2 {
    display: flex;
    align-items: center;
    gap: var(--nd-size-2x);
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.column-1 {
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-base);
    width: 100%;
}

.row-1 {
    display: flex;
    align-items: center;
    gap: var(--nd-size-base);
}

.row {
    display: flex;
    align-items: center;
}

.column-2 {
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-2x);
    width: 100%;
}

.row-3 {
    display: flex;
    align-items: center;
    gap: var(--nd-size-3x);
}

.column-3 {
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-3x);
    width: 100%;
}

.row-4 {
    display: flex;
    align-items: center;
    gap: var(--nd-size-4x);
}

.column-4 {
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-4x);
    width: 100%;
}

.column-6 {
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-6x);
    width: 100%;
}

.text-secondary {
    color: var(--text-icons-light-secondary);
}

.text-error {
    color: var(--accent-light-red);
}

.text-minor {
    color: var(--text-minor);
}

#modal-container {
    position: fixed;
    z-index: 10001;
}

.popup-actions {
    display: flex;
    flex-direction: column;
    background-color: var(--background-light-primary);
    border-radius: var(--nd-size-4x);
    box-shadow: var(--nd-base-shadow);

    > * {
        justify-content: flex-start;
    }
}
