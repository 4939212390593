.scenarios-filters {
  display: flex;
  gap: var(--nd-size-10x);
  margin-bottom: var(--nd-size-3x);

  &__grid {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: var(--nd-size-3x);

    > * {
      width: 370px;
    }

    > button {
      width: min-content;
    }
  }

  &__actions {
    margin-left: auto;
  }

  &__select-filters {
    position: relative;
  }

  &__filter-title-select {
    position: absolute !important;
    .nd-custom-select__control {
      border-radius: 100px;
      opacity: 0;
    }

    .nd-custom-select__menu {
      width: 360px;
      z-index: 2;
      font: var(--body-2)
    }
  }
}