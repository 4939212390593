@import "../../mixins.scss";

.sidebar {
  --unfolded-width: 272px;
  --folded-width: 64px;
  --toggle-button-height: 40px;
  --item-height: 48px;
  --header-height: 78px;
  --outer-corner-size: 32px;
  --project-selector-height: 64px;

  background-color: var(--day-background-minor);
  width: var(--folded-width);
  height: 100vh;
  flex: none;
  transition: .3s;
  direction: rtl;
  position: relative;
  z-index: 10;

  filter: drop-shadow(2px 0 5px rgba(0,0,0, .1));

  &__toggle-button::after,
  &::before {
    content: "";
    position: absolute;
    right: calc(-1 * var(--outer-corner-size));
    height: var(--outer-corner-size);
    width: var(--outer-corner-size);
    background-color: var(--day-background-minor);
    z-index: -1;
  }
  &__toggle-button::after {
    bottom: 0;
    clip-path: path('M 0 32 L 32 32 Q 0 32 0 0 z');
  }
  &::before {
    clip-path: path("M 0 0 L 32 0 Q 0 0 0 32 z");
  }

  &__extra-items {
    margin-top: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    direction: ltr;
    overflow-y: auto;
    height: calc(100%
      - var(--header-height)
      - var(--project-selector-height)
      - var(--toggle-button-height)
    );

    // Hide scrollbar
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__toggle-button {
    position: absolute;
    height: var(--toggle-button-height);
    bottom: 0;
    width: 100%;
    border: none;
    border-top: 1px solid rgba(138, 135, 132, 0.4);
    background-color: var(--day-background-minor);
    cursor: pointer;

    &:hover {
      &,
      &::after {
        @include hover(var(--day-background-minor));
      }
    }
    &:active {
      &,
      &::after {
        @include pressed(var(--day-background-minor));
      }
    }
  }

  &__toggle-icon {
    transition: .3s;
  }

  &_expanded {
    width: var(--unfolded-width);
  }

  &_expanded &__toggle-icon {
    transform: rotate(180deg);
  }

  &_expanded &__content {
    overflow: auto;
  }

  .new-project-selector {
    z-index: 2;
  }
}