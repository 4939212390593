@import "../../mixins.scss";

.nd-textarea {
  --bg-color: var(--control-minor);

  display: flex;
  position: relative;
  flex-direction: column;

  &_fluid,
  &_fluid textarea {
    width: 100%;
  }

  textarea {
    transition: .1s;
    background-color: var(--bg-color);
    padding: var(--nd-size-2_5x) var(--nd-size-4x);
    border: none;
    border-radius: var(--control-border-radius-m);
    box-sizing: border-box;
    resize: none;
    font: var(--body-2);
    min-height: calc(var(--control-height-m) - 2px);
    color: var(--text-icons-light-primary);

    &:focus,
    &:hover:not(:disabled) {
      @include focus;
    }

    &::placeholder {
      color: var(--text-minor);
    }
  }

  &_disabled {
    @include disabled;
  }

  &_contrast {
    --bg-color: var(--control-contrast);
  }

  &_resize-on-input textarea::-webkit-scrollbar {
    width: 0;
  }

  &__max-label {
    font: var(--caption-1);
    color: var(--text-minor);
    align-self: flex-end;
    padding-right: var(--nd-size-4x);
    padding-top: var(--nd-size-base);
  }

  &__clear-button {
    display: none;

    position: absolute;
    bottom: var(--nd-size-1_5x);
    right: var(--nd-size-1_5x);
    mix-blend-mode: darken;
  }

  &_with-max-length &__clear-button {
    bottom: var(--nd-size-6x);
  }

  &:hover &__clear-button {
    display: block;
  }
}