.nd-scenario-create-modal {
  width: 640px;
    
  .modal-header {
    box-shadow: 0 7px 10px -2px var(--text-icons-light-secondary);  
    padding: 8px 16px !important;
    font: var(--body-1) !important;
  }
  .modal-body > * {
    margin-top: var(--nd-size-2x);

    &:nth-child(4) {
      margin-top: 0;
    }
  }
  .modal-body label {
    margin-top: var(--nd-size-3x);
  }
  .modal-body .nd-input_floating.nd-input_with-value .nd-input__placeholder {
    top: var(--nd-size-0_5x);
  }
  .modal-footer .row > * {
    height: 50px;
  }
}
