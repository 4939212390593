.nd-datepicker-date-time-inputs {
  display: flex;
  align-items: center;
  gap: var(--nd-size-2x);
  border-top: 1px solid var(--line);
  flex-direction: column;
  width: 100%;
  padding-top: var(--nd-size-2x);

  &__help-buttons {
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    gap: var(--nd-size-2x);
    margin-right: var(--nd-size-base);
    border-radius: var(--nd-border-radius-large);

    &:active {
      margin-right: var(--nd-size-base);
    }
  }

  &__inputs {
    grid-column: span 2;
    width: 100%;
    display: flex;
    align-items: center;
    gap: var(--nd-size-2x);

    input {
      border-radius: 16px;
      align-self: stretch;
    }

    .nd-button {
      width: 130px;
      margin-left: auto;
    }
  }
}