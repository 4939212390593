.create-feature-modal {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--nd-size-3x);

    padding: var(--nd-size-4x);
  }

  &__slug,
  &__description,
  &__clarifying-question {
    grid-column: 1 / -1;
  }

  &__force-question {
    flex-shrink: 0;
    word-break: keep-all;
  }
}