@import "../../mixins.scss";

.project-selector-selected-value {
  --icon-size: 40px;

  direction: ltr;
  display: grid;
  grid-template-columns: var(--nd-sidebar-folded-width);
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--day-background-minor);
  padding: var(--nd-size-base) 0;
  box-sizing: border-box;
  gap: var(--nd-size-2x);
  font: var(--body-2);
  margin-bottom: var(--nd-size-4x);
  cursor: pointer;
  width: var(--nd-sidebar-folded-width);
  margin-right: auto;
  text-align: left;

  &_extended {
    width: var(--nd-sidebar-unfolded-width);
    grid-template-columns: 56px 1fr 24px;
  }
  &_extended &__project-icon {
    margin: initial;
    margin-left: auto;
  }

  &:hover {
    @include hover(var(--day-background-minor));
  }

  &:focus {
    @include pressed(var(--day-background-minor));
  }

  &__project-icon {
    width: var(--icon-size);
    height: var(--icon-size);
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    margin: auto;
  }

  &__chevron {
    transition: .2s;
    margin-right: auto;
  }

  &_open &__chevron {
    transform: rotate(180deg);
  }
}