@import '../../mixins.scss';

.nd-table-new {
  --negative-margin: 0px;
  --min-cell-width: 120px;

  --header-background-color: var(--background-light-primary);
  --row-background-color: var(--background-light-primary);
  --active-row-background-color: #F5F4F2;
  --delimiter: rgba(33, 32, 31, 0.5);

  display: grid;
  min-width: 100%;
  position: relative;
  overflow: auto;
  isolation: isolate;
  grid-auto-rows: max-content;
  margin: 0 calc(-1 * var(--negative-margin));

  &__wrapper {
    isolation: isolate;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__header {
    grid-row: 1 / 3;
  }

  &__header-cell {
    grid-row: 1 / 3;

    &_short {
      grid-row: 2 / 3;
    }
  }

  &__group-title {
    grid-row: 1 / 2;
  }

  &__group-title {
    text-align: left;
    font: var(--caption-1);
    color: var(--text-minor);
  }

  &__header-cell {
    white-space: nowrap;
    min-height: 40px;
    min-width: auto;
  }
  &__header-cell:hover {
    background: var(--header-background-color);
  }
  &__header-cell:focus {
    outline: none;
  }

  &__row,
  &__folder-wrapper,
  &__header,
  &__row-with-addition-data {
    display: contents;
  }

  &__row:hover .nd-table-new__cell {
    @include hover(var(--row-background-color));
  }

  &_rows-display-mode_separated {
    filter: drop-shadow(0 2px 5px rgba(0,0,0,.1));
    box-sizing: border-box;
    gap: var(--nd-size-base) 0;
  }

  &_rows-display-mode_separated &__cell {
    border-bottom: none;

    &:first-child {
      clip-path: xywh(0px 0px 150% 100% round 12px 12px) !important;
      padding-left: var(--nd-size-3x);
      margin-left: var(--negative-margin);

      border-top-left-radius: var(--nd-size-3x);
      border-bottom-left-radius: var(--nd-size-3x);
    }
    &:last-child {
      margin-right: var(--negative-margin);
      padding-right: var(--nd-size-2x);

      border-top-right-radius: var(--nd-size-3x);
      border-bottom-right-radius: var(--nd-size-3x);
    }
  }

  &_rows-display-mode_separated.horizontal-scrolled.nd-table-new_sticky-first-column &__header-cell:first-child {
    background-color: var(--header-background-color);
  }

  &_rows-display-mode_separated &__group-title {
    margin-bottom: calc(-2 * var(--nd-size-base));
    padding-bottom: calc(2 * var(--nd-size-base));
  }

  &__row_clickable {
    cursor: pointer;
  }

  &__cell {
    background-color: var(--row-background-color);
    min-height: 56px;
    min-width: var(--min-cell-width);
  }

  &__header-cell,
  &__group-title {
    background-color: var(--header-background-color);
  }

  &__cell,
  &__header-cell,
  &__group-title {
    padding: 0 var(--nd-size-2x);
  }

  &__cell,
  &__header-cell{
    display: flex;
    align-items: center;
    text-align: left;
    box-sizing: border-box;
    border: none;
  }

  &__cell:first-child,
  &__header-cell:first-child {
    padding-left: calc(var(--nd-size-2x) + var(--negative-margin));
  }
  &__cell:last-child,
  &__header-cell:last-child {
    padding-right: calc(var(--nd-size-2x) + var(--negative-margin));
  }


  &__group {
    position: relative;
    align-self: flex-end;

    display: flex;
    flex-direction: column;
    gap: var(--nd-size-2x);
  }

  .group-start {
    border-left: 1px solid var(--delimiter);
    margin-left: -1px;
  }
  .group-end {
    border-right: 1px solid var(--delimiter);
  }

  &.vertical-scrolled {
    .nd-table__header {
      box-shadow: 0 -8px 30px 0 rgba(0,0,0,.8);
      min-width: calc(100%);
    }
  }

  &_sticky-first-column &__cell:first-child,
  &_sticky-first-column &__header-cell:first-child {
    z-index: 1;
    position: sticky;
    left: 0;
  }
  &_sticky-first-column.nd-table-new_rows-display-mode_separated &__header-cell:first-child {
    left: calc(-1 * var(--negative-margin));
  }

  &_sticky-first-column.horizontal-scrolled &__cell:first-child,
  &_sticky-first-column.horizontal-scrolled &__header-cell:first-child {
    box-shadow: 0 0 12px 6px rgba(0,0,0,.2);
    clip-path: inset(0 -50px 0 0);
  }

  &_sticky-header &__header-cell,
  &_sticky-header &__group-title {
    position: sticky;
    top: 0;
    z-index: 1;

    &_short {
      top: 15px; // Отступ под group-title
    }
  }
  &_sticky-header &__header-cell:first-child {
    z-index: 2;
  }

  &__cell {
    font: var(--body-2);
    border-bottom: 1px solid var(--control-minor);
    word-break: break-word;

    padding-top: var(--nd-size-2x);
    padding-bottom: var(--nd-size-2x);
  }

  &__header-cell,
  &__group-title {
    font: var(--caption-1);
    font-weight: normal;
  }

  &__header-cell {
    border-bottom: 1px solid var(--delimiter);

    &.nd-button {
      border-radius: 0;
      justify-content: flex-start;
      gap: var(--nd-size-2x);
    }

    &_sortable {
      cursor: pointer;
    }
  }

  &__sort-button-wrapper {
    padding-left: var(--nd-size-2x);
  }

  &__add-toggle-button-wrapper {
    position: absolute;
    right: 8px;
    transform: translateY(-50%);
  }

  &__additional {
    position: relative;
    height: 0;
    grid-column: 1 / -1;
  }

  &__row-with-addition-data {
    .nd-accordion__header-button {
      position: relative;

      & > .icon {
        position: absolute;
        right: var(--nd-size-4x);
      }

      &:hover {
        color: inherit !important;
      }

      .calls-reports-history-item-status_status_error {
        color: var(--accent-light-red);
      }
    }
  }

  &__stab {
    grid-column: 1 / -1;
    margin: var(--nd-size-base) var(--negative-margin);
    @include skeleton(auto);
    height: 80px;
  }

  &__fake-scrollbar-wrapper {
    width: auto;
    height: 7px;
    z-index: 4;
    overflow: auto;
    margin-bottom: var(--nd-size-base);
    transform: scaleX(0.96);
    flex-shrink: 0;
  }

  &__fake-scrollbar-wrapper::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &__fake-scrollbar-wrapper::-webkit-scrollbar {
    height: 6px;
    background-color: var(--control-minor);
    border-radius: 4px;
  }
  &::-webkit-scrollbar {
    height: 0;
    width: 6px;
    background-color: var(--control-minor);
    border-radius: var(--nd-size-base);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--day-fog);
    border-radius: var(--nd-size-base);
  }

  &__fake-scrollbar-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--day-fog);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}

.nd-table-sorting-options-list {
  &,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  border-radius: var(--control-border-radius-l);
  background: var(--background-light-primary);
  box-shadow: var(--nd-popup-shadow);

  &__item:first-child &__button {
    border-top-right-radius: var(--control-border-radius-l);
    border-top-left-radius: var(--control-border-radius-l);
  }

  &__item:last-child &__button {
    border-bottom-right-radius: var(--control-border-radius-l);
    border-bottom-left-radius: var(--control-border-radius-l);
  }

  &__button {
    margin: 0;
    padding: var(--nd-size-4x);
    display: grid;
    grid-template-columns: 1fr var(--nd-size-6x);
    gap: var(--nd-size-5x);
    width: 100%;
    text-align: left;
    border-radius: 0;
  }
}