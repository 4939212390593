.card {
  --padding-small: var(--nd-size-2x);
  --padding-medium: var(--nd-size-4x);
  --padding-large: var(--nd-size-6x);

  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  background-color: var(--background-light-primary);

  &_no-padding {
    padding: 0;
  }

  &_v-padding_none {
    padding-top: 0;
    padding-bottom: 0;
  }
  &_h-padding_none {
    padding-left: 0;
    padding-right: 0;
  }

  &_v-padding_small {
    padding-top: var(--padding-small);
    padding-bottom: var(--padding-small);
  }
  &_h-padding_small {
    padding-left: var(--padding-small);
    padding-right: var(--padding-small);
  }

  &_v-padding_medium {
    padding-top: var(--padding-medium);
    padding-bottom: var(--padding-medium);
  }
  &_h-padding_medium {
    padding-left: var(--padding-medium);
    padding-right: var(--padding-medium);
  }

  &_v-padding_large {
    padding-top: var(--padding-large);
    padding-bottom: var(--padding-large);
  }
  &_h-padding_large {
    padding-left: var(--padding-large);
    padding-right: var(--padding-large);
  }

  &_border-radius_small {
    border-radius: var(--nd-size-2x);
  }
  &_border-radius_medium {
    border-radius: var(--nd-size-4x);
  }
  &_border-radius_large {
    border-radius: var(--nd-size-6x);
  }
}