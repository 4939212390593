.loading {
  display: flex;
}

.dot {
  border-radius: 50%;
  margin: var(--nd-size-base);
  width: 16px;
  height: 16px;
  background-color: var(--accent-light-yellow);
}

@keyframes dot-1 {
  from { opacity: 1 }
  50% { opacity: 0.5 }
  to { opacity: 1 }
}

@keyframes dot-2 {
  from { opacity: 0.75 }
  25% { opacity: 0.5 }
  50% { opacity: 0.75 }
  75% { opacity: 1 }
  to { opacity: 0.75 }
}

@keyframes dot-3 {
  from { opacity: 0.5 }
  25% { opacity: 0.75 }
  50% { opacity: 1 }
  75% { opacity: 0.75 }
  to { opacity: 0.5 }
}

.loading-dot-1 {
  animation: dot-1 1s linear normal infinite;
}

.loading-dot-2 {
  animation: dot-2 1s linear normal infinite;
}

.loading-dot-3 {
  animation: dot-3 1s linear normal infinite;
}