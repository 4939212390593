.call-modal {
  --footer-height: 72px;

  .nd-side-modal__header {
    box-shadow: var(--nd-popup-shadow);
  }

  .react-datepicker-popper {
    width: max-content;
  }

  &__content {
    padding: var(--nd-size-4x);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-4x);
    padding-bottom: var(--footer-height);
  }

  &__heading {
    font: var(--body-1);
    font-weight: bold;
  }

  &__toggle-label {
    display: flex;
    gap: var(--nd-size-base);
    align-items: center;
    justify-content: space-between;
    min-height: 48px;
  }

  &__details-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    gap: 12px 40px;
    flex-direction: column;
    box-sizing: border-box;
  }

  &__time {
    display: flex;
    gap: var(--nd-size-base);
  }

  &__time-setup-container {
    display: flex;
    gap: var(--nd-size-base);
    align-items: flex-start;
    flex-direction: column;
  }

  &__row {
    display: flex;
    gap: 34px;
    flex-wrap: wrap;
  }

  &__date-control {
    grid-column: 1 / 3;

    display: flex;
    flex-direction: column;
    gap: var(--nd-size-base);

    > span {
      color: var(--text-minor);
    }
  }

  &__error {
    color: var(--accent-light-red);
  }
}