.sort-sign {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;

  position: relative;

  &__fade {
    position: absolute;
    width: 50%;
    height: 100%;
    opacity: 0;
    top: 0;
    background-color: var(--background-light-primary);
    pointer-events: none;

    &.desc,
    &.asc {
      opacity: .80;
    }

    &.asc {
      right: 0;
    }

    &.desc {
      left: 0;
    }
  }
}