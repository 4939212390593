@import "../../mixins.scss";

.nd-custom-select,
#modal-container {
  --option-padding: var(--nd-size-3x) var(--nd-size-6x);
  --option-padding-negative: calc(-1 * var(--nd-size-3x)) calc(-1 * var(--nd-size-6x));
}

.nd-custom-select {
  --container-height: var(--control-height-m);
  --border-radius: var(--control-border-radius-m);

  width: 100%;
  font-size: var(--body-2);
  line-height: calc(var(--body-2) + 1px);

  &:hover &__control,
  &:has(input:focus) &__control {
    outline: var(--focus-outline-border);
    @include focus;
  }

  & &__control {
    border-color: transparent;
    background-color: var(--control-minor);
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    height: var(--container-height);
    border-radius: var(--border-radius);

    &:focus,
    &--is-focused {
      border-color: transparent;
      box-shadow: none;

      &:hover {
        border-color: var(--focus-color);
      }
    }
  }

  &_fluid  &__control {
    width: 100%;
  }

  &_contrast &__control {
    background-color: var(--background-light-secondary);
  }

  &_contrast &__multi-value {
    background-color: var(--warm-gray);
  }

  &_size_large {
    --container-height: var(--control-height-l);
    --border-radius: var(--control-border-radius-l)
  }

  &_transparent &__control {
    background-color: transparent;
    border: none;
    box-shadow: none;

    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  &_transparent &__value-container {
    padding-left: 0;
  }

  &_mode_underline &__control {
    background-color: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(138, 135, 132, 0.4);
    outline: none;
    outline: none !important;
    padding-left: 0;

    &:hover {
      box-shadow: none;
    }

    &:focus,
    &--is-focused {
      border-top: none;
      border-right: none;
      border-left: none;
      box-shadow: none;
      border-bottom-color: var(--nd-focus-color);
    }
  }

  & &__placeholder {
    color: var(--text-minor);
    margin: 0;
  }

  &_short-list &__menu-list {
    max-height: 160px;
  }

  & &__single-value {
    display: flex;
    align-items: center;
  }

  & &__value-container {
    padding: 0;
    height: 100%;
  }

  &_disabled {
    @include disabled;
  }

  & &__clear {
    right: var(--nd-size-6x);
    cursor: pointer;
    margin-right: 8px;
    display: inline-flex;

    &:hover {
      background: transparent;

      svg {
        opacity: 0.8;
      }
    }

    svg {
      opacity: 0.3;
    }
  }

  &__multi-value {
    border-radius: var(--nd-border-radius);
  }

  &__multi-value-text {
    flex-wrap: nowrap !important;
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loading-wrapper {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input input {
    min-width: 0;

    input {
      max-width: 100%;
    }
  }

  .nd-custom-select-multi-select-input {
    display: flex;
    align-items: center;

    &__placeholder {
      border-radius: var(--nd-border-radius);
      background: var(--day-background-minor);
      color: var(--text-minor);
      padding: var(--nd-size-base) var(--nd-size-3x);
      margin-left: calc(-1 * var(--nd-size-2x));
    }
  }
}

#modal-container,
.nd-custom-select {
  .nd-custom-select__menu {
    border-radius: var(--nd-border-radius-large);
    box-shadow: 0 8px 20px rgba(117, 115, 111, 0.2);
    overflow: hidden;
    min-width: 160px;
  }

  .nd-custom-select__menu-list {
    padding: 8px 0;
  }

  .nd-custom-select__option {
    --background-color: var(--background-light-primary);

    position: relative;
    padding: var(--option-padding);
    display: flex;
    align-items: center;
    gap: var(--nd-size-2x);
    background-color: var(--background-color);

    &:hover,
    &:focus {
      @include hover(var(--background-color));
    }

    &:focus {
      @include focus;
    }

    .option-subtitle {
      display: block;
      font: var(--caption-1);
      color: var(--text-minor);
      text-align: left;
    }

    &--is-disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  &__selected-icon {
    margin-left: auto;
  }
}