.nd-project-auth-data-modal {
  &__item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: var(--nd-size-4x);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: var(--nd-size-2x);
  }

  &__text, &__input input, &__placeholder {
    font: var(--caption-1);
  }

  &__text {
    max-width: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__placeholder {
    color: var(--text-minor);
  }

  &__ip-addresses {
    margin-top: var(--nd-size-2x);
    max-height: 40vh;
    overflow: auto;
    padding: 1px;
  }

  &__ip-address {
    display: flex;
    width: 100%;
    gap: var(--nd-size-2x);
  }

  &__ip-address:not(:last-child) {
    margin-bottom: var(--nd-size-base);
  }

  &__key-wrapper {
    display: flex;
    align-items: center;
  }

  &__key-action {
    margin: 0;
    padding: var(--nd-size-base);
  }
}
