@import "../../mixins.scss";

.nd-checkbox {
  --size: 32px;
  --background-color: var(--control-minor);
  --border-radius: 8px;

  display: flex;
  align-items: center;
  width: fit-content;
  position: relative;
  cursor: pointer;
  border: none;
  text-align: left;
  gap: var(--nd-size-2x);
  position: relative;

  min-height: var(--size);
  min-width: var(--size);

  &:has(input:checked) {
    --background-color: var(--control-main);
  }

  &_size_small {
    --size: 24px;
    --border-radius: 4px;
  }

  &_size_medium {
    --size: 32px;
  }

  &_size_medium &__mask {
    width: var(--size);
    height: var(--size);
  }

  &_bold {
    font-weight: 700;
  }

  &__mask {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background-color);
    border-radius: var(--border-radius);
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.05);
    position: absolute;
    height: var(--size);
    width: var(--size);
  }
  &:hover &__mask {
    @include hover(var(--background-color));
  }
  &:active &__mask {
    @include pressed(var(--background-color));
    @include focus;
  }
  &:has(input:focus) &__mask {
    @include focus;
  }
  &:has(input:disabled) {
    @include disabled;
  }

  &__label {
    display: block;
    padding-left: calc(var(--size) + var(--nd-size-2x));
  }

  &_skeleton {
    cursor: default;
    flex-grow: 1;
    width: 100%;
    animation: blink 1s ease-out infinite;

    @keyframes blink {
      from {
        opacity: 0.5;
      }
      to {
        opacity: 0.9;
      }
    }
  }

  &_skeleton &__mask,
  &_skeleton:hover &__mask,
  &_skeleton:focus &__mask {
    box-shadow: none;
    background: var(--stab-bg-color);
  }

  &_skeleton  &__label {
    margin-left: calc(var(--size) + var(--nd-size-3x));
    padding-left: 0;
    background-color: var(--stab-bg-color);
    border-radius: var(--nd-border-radius);
    color: transparent;
    min-width: 70%;
  }

  input {
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
  }
}