@import '../../mixins.scss';

.release-notes-list {
  &__header {
    display: flex;
    align-items: center;
    gap: var(--nd-size-2x);
  }
}

.release-notes-editor {
  .nd-input.nd-input_size_medium {
    width: 150px;
  }

  &__content {
    padding: 0 var(--nd-size-4x) 60px;
  }

  &__row {
    display: grid;
    grid-template-columns: 120px 1fr;
    margin-bottom: var(--nd-size-2x);
    align-items: center;
    gap: var(--nd-size-2x);
  }

  &__action-buttons {
    @include actionButtons;
  }
}

.release-notes-last {
  position: fixed;
  z-index: 1001;
  top: var(--nd-size-6x);
  right: var(--nd-size-6x);
  background-color: var(--background-light-primary);
  box-shadow: var(--nd-popup-shadow);
  padding: var(--nd-size-4x);
  border-radius: var(--nd-border-radius-extra-large);
  min-width: 400px;
  max-width: 400px;
}

.release-notes-item {
  max-width: 640px;

  &__header {
    display: flex;
    align-items: flex-start;
    gap: var(--nd-size-2x);
  }

  &__header-texts {
    flex-grow: 2;
  }

  &__title {
    margin: 0;
    font: var(--body-1);
    font-weight: var(--font-weight-bold);
  }

  &__content {
    margin: var(--nd-size-4x) 0;

    .anchor { display: none; }

    &_short {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}