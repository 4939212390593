.side-modal-content-island {
  &:not(&_not-subtitle) {
    &:first-child {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      margin-top: 0;
      padding-top: 0;
    }
  }

  &:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
    padding-bottom: 80px;
    flex-grow: 1;
  }

  border-radius: var(--nd-border-radius-extra-large);
  background-color: var(--background-light-primary);
  padding: var(--nd-size-6x) var(--nd-size-4x);
  box-sizing: border-box;
  width: 100%;

  &__label {
    font: var(--body-2);

    &:not(:last-child) {
      margin-bottom: var(--nd-size-2x);
    }

    &_error {
      color: var(--accent-light-red);
    }
  }
}
