.nd-markdown-editor {
  .w-md-editor {
    border-radius: var(--nd-border-radius);
    overflow: hidden;
    background-color: var(--control-minor);
    box-shadow: none;
    border-color: var(--line);
  }

  .w-md-editor-toolbar {
    padding: var(--nd-size-3x) var(--nd-size-2x);
    background-color: transparent;
    border-bottom-color: var(--line);

    li button:hover,
    li button:focus,
    li.active button {
      background: transparent;
      color: var(--accent-light-blue);
    }
  }

  .wmde-markdown {
    border-right: 1px solid var(--line);
    border-bottom: 1px solid var(--line);
    overflow: hidden;
    border-bottom-right-radius: var(--nd-border-radius);
  }

  .w-md-editor-bar {
    bottom: var(--nd-size-base);
    right: var(--nd-size-2x);
  }

  .w-md-editor {
    color: var(--nd-text-color);
  }

  .w-md-editor-toolbar-divider {
    &,
    & + li {
      display: none;
    }
  }

  .w-md-editor-text-pre > code,
  .w-md-editor-text-input,
  .wmde-markdown-color {
    font: var(--body-2) !important;
  }

  textarea::placeholder {
    color: var(--text-minor);
  }
}
.wmde-markdown strong{
  font-family: sans-serif;
}