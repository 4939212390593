.create-samplings-modal {
  width: 480px;

  .modal-header {
    padding: 16px !important;
  }

  .name-container {
    flex-grow: 1;
  }
  .quantity-container {
    width: 160px;
  }
}