.nd-side-modal {
  --close-button-side: 48px;
  --outer-close-button-offset: 8px;

  position: fixed;
  top: 0;
  bottom: 0;
  width: 640px;
  max-width: 90vw;
  background: var(--background-light-primary);
  z-index: 1000;
  box-shadow: var(--nd-popup-shadow);
  display: flex;
  flex-direction: column;

  .reference-header {
    display: flex;
    gap: var(--nd-size-2x);
  }

  &_contrast-background {
    background-color: var(--day-background-minor);
  }

  &_placement_right {
    right: 0;
    border-top-left-radius: var(--nd-border-radius-large);
    border-bottom-left-radius: var(--nd-border-radius-large);

    .nd-side-modal__header {
      border-top-left-radius: var(--nd-border-radius-large);
    }
  }

  &_placement_left {
    left: 0;
    border-top-right-radius: var(--nd-border-radius-large);
    border-bottom-right-radius: var(--nd-border-radius-large);
  }

  &_placement_center {
    left: 50%;
    transform: translateX(-50%);
    top: var(--nd-size-6x);
    bottom: var(--nd-size-6x);
    border-radius: var(--nd-size-6x);
    overflow: hidden; 
  }

  &_low {
    top: 17vh;
    border-top-right-radius: var(--nd-border-radius-large);
  }

  &_size {
    &_slim {
      max-width: 360px;
    }

    &_medium {
      width: 700px;
    }

    &_large {
      width: 896px;
    }

    &_wide {
      min-width: 900px;
      width: auto;
      max-width: 80vw;
    }
  }

  &_low &__close-button {
    margin: 0;
    background: var(--background-light-primary);
    border-radius: 50%;
    width: var(--close-button-side);
    height: var(--close-button-side);
    top: calc(-1 * (var(--close-button-side) + var(--outer-close-button-offset)));
    right: var(--outer-close-button-offset);
  }

  &__reference-link {
    margin-top: var(--nd-size-base);
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--day-fog);
    z-index: 1000;
    animation: fadeIn .2s;
  
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  &__content {
    overflow: hidden auto;
    max-height: calc(100% - 62px);
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    &_islands {
      padding-top: 0;
      background-color: var(--day-background-minor);
      gap: var(--nd-size-3x);
    }
  }

  &__footer {
    margin-top: auto;
    display: flex;
    gap: var(--nd-size-2x);
    padding: var(--nd-size-2x);
    box-shadow: 0px -4px 20px 0px #0000001F;
    border-radius: var(--nd-size-6x) var(--nd-size-6x) 0 0;
    justify-content: stretch;
    z-index: 1;
  }

  &__header {
    padding: var(--nd-size-4x);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--background-light-primary);
    position: relative;
    flex-shrink: 0;
    z-index: 1;
    gap: var(--nd-size-2x);
    box-shadow: 0px 4px 12px 0px #0000001A;
    word-break: break-word;

    &_with-shadow {
      box-shadow: var(--nd-popup-shadow);
      position: relative;
    }

    &_rounded {
      border-bottom-right-radius: var(--nd-border-radius-extra-large);
      border-bottom-left-radius: var(--nd-border-radius-extra-large);
      margin-bottom: calc(-1 * var(--nd-border-radius-extra-large));
    }

    h2 {
      margin: 0;
    }

    input,
    h2 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    min-height: 40px;
  }

  &__header_rounded + &__content {
    padding-top: calc(var(--nd-size-4x) + var(--nd-border-radius-extra-large));
  }

  &__close-button {
    z-index: 2;
  }

  &__reset-button {
    margin-right: 0;
  }
}