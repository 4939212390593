.calls-settings-modal {
  --footer-height: 72px;

  .nd-side-modal__header {
    box-shadow: var(--nd-popup-shadow);
  }

  &__comment {
    color: var(--text-icons-light-secondary);
    font: var(--caption-1);
    white-space: pre-wrap;
  }

  &__time-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    gap: 12px 40px;
  }

  &__content {
    padding: var(--nd-size-4x);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-4x);
    padding-bottom: var(--footer-height);
  }

  &__heading {
    font: var(--body-1);
    font-weight: var(--font-weight-bold);
  }

  &__toggle-label {
    display: flex;
    gap: var(--nd-size-base);
    align-items: center;
    justify-content: space-between;
    min-height: 48px;
  }

  &__details-wrapper {
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
  }

  &__time {
    display: flex;
    gap: var(--nd-size-base);
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    gap: var(--nd-size-base);

    padding: var(--nd-size-2x);
    box-sizing: border-box;
    box-shadow: var(--nd-base-shadow);
    border-radius: var(--nd-size-6x) var(--nd-size-6x) 0 0;
    min-height: var(--footer-height);

    > * {
      flex: 1 1 0;
    }
  }
}