.audio-meta {
  display: block;
  width: -webkit-fill-available;

  &__title {
    display: flex;
    flex-wrap: nowrap;

    > span {
      word-wrap: none;
      word-break: keep-all;
    }
  }
  &__subtitle {
    color: var(--text-icons-light-secondary);
    font: var(--caption-1);
  }

  &__duration {
    position: relative;
    padding-left: 8px;
    margin-left: 4px;
    color: var(--text-icons-light-secondary);
    &::before {
      content: '•';
      position: absolute;
      left: 0;
    }
  }
}