.export-calls-modal {
  &__filters-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--nd-size-2x);
    word-break: break-word;
  }

  &__filter-name,
  &__filter-value {
    display: block;
  }

  &__filter-name {
    color: var(--text-minor);
  }

  &__name-input {
    grid-column: 1 / 3;
  }
}