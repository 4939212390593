.update-audio-record-modal {
  width: 447px;

  &__input {
    width: 100%;
    input {
      width: 100%;
    }
  }

  &__textarea textarea {
    height: 96px;
  }
}