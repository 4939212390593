.user-created-modal {
  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-4x);
    font: var(--caption-1);
    margin-top: var(--nd-size-base);
  }

  &__message {
    text-align: center;
    width: 400px;
    margin: 0 auto;
  }

  &__warning {
    color: var(--accent-light-red);
  }

  &__password {
    margin: auto;
    font: var(--body-2);
    display: flex;
    gap: var(--nd-size-5x);
    align-items: center;
  }

  &__confirm-button {
    min-width: 200px;
  }
}