.value-container-floatin {
  --icon-size: 40px;

  isolation: isolate;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  overflow: hidden;
  text-overflow: ellipsis;

  // &__value-wrapper>*:has(.nd-custom-select__input) - селектор
  // родительского компонента над инпутом

  &__placeholder {
    color: var(--placeholder-color);
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: initial;
  }

  &__placeholder {
    transition: font-size .2s;
  }
  &__value-wrapper {
    position: relative;
    transition: min-height .2s;
  }

  &__value-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 0;
    position: relative;
    display: flex;

    &_all-selected {
      position: absolute;
      .nd-custom-select__multi-value-floating
       {
        display: none;
      }
    }
  }

  &__value-wrapper>*:has(.nd-custom-select__input) {
    flex-grow: 1;
    display: inline-flex;
    position: absolute;
    left: 0;
    z-index: 0;
  }

  &_with-icon {
    padding-left: calc(var(--icon-size));
  }

  &__icon {
    width: var(--icon-size);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  &:has(input:focus) {
    .value-container-floatin__value-wrapper_all-selected {
      position: relative;
    }
    .value-container-floatin__all-values {
      display: none;
    }
  }

  &:has(input:focus) &__value-wrapper,
  &_with-value &__value-wrapper {
    min-height: 1.35rem;
  }
  &:has(input:focus) input {
    max-width: 100%;
  }

  &:has(input:focus) &__placeholder,
  &_with-value &__placeholder {
    font: var(--caption-1);
    margin-bottom: -2px;
  }

  &__value-wrapper>*:has(.nd-custom-select__input) {
    padding: 0;
    margin: 0;
    overflow: hidden;
    border: none;
  }

  .nd-custom-select__single-value {
    margin: 0;
    display: inline-flex;
  }

  .nd-custom-select__input {
    display: block !important; // Переопределение библиотечных стилей
  }

  &__overflow-counter {
    display: inline-block;
    top: 0;
    border-radius: 8px;
    background-color: rgb(224,224,224);
    width: 36px;
    text-align: center;
    outline: 1px solid var(--line);
    outline-offset: -1px;
  }

  &__total-list {
    display: flex;
    flex-direction: column;
    background-color: var(--background-light-primary);
  }

  &__overflow-wrapper {
    position: absolute;
    right: 0;
    z-index: 3;
  }

  &__hint {
    color: var(--text-minor);
    font: var(--caption-2);
  }
}

.nd-custom-select__control:has(.value-container-floatin_with-icon) {
  padding-left: 0 !important;
}