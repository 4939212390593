.window-plug {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  font-size: 28px;
  white-space: break-spaces;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  z-index: 1;
  gap: 8px;
  
  @media (max-width: 900px) {
    display: flex;
  }

  span {
    font-size: 16px;
  }
}