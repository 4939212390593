.slots-table {
  width: 100%;

  &__row {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 40px;
  }

  &__th {
    border-bottom: 1px solid var(--applied-light-separator);
  }
}