.new-project-selector {
  --height: 48px;
  --icon-size: 40px;
  --shadow-color: rgba(0, 0, 0, .08);

  --option-vertical-padding: var(--nd-size-3x);
  --option-horizontal-padding: var(--nd-size-4x);

  padding: 0;
  border-radius: 30px;
  direction: ltr;

  &:hover {
    .nd-custom-select__control {
      box-shadow: 0 0 4px 2px var(--shadow-color);
    }
  }
  &:active {
    --shadow-color: rgba(0, 0, 0, .12);
  }

  &__create-project-button,
  &__current-project-button,
  .nd-custom-select__option {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: var(--nd-size-2x);
    border-radius: 0;
    padding: var(--option-vertical-padding) var(--option-horizontal-padding);
    box-sizing: border-box;
  }

  &__project-icon,
  .nd-custom-select__icon-wrapper {
    flex-shrink: 0;
    width: var(--icon-size);
    height: var(--icon-size);
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.08);
  }

  &__delimiter {
    width: calc(100% - 48px);
    border-top: 1px solid rgba(138, 135, 132, 0.4);
    opacity: .5;
    margin: auto;

    &:first-of-type {
      margin-top: var(--nd-size-2x);
    }

    &:last-of-type {
      margin-bottom: var(--nd-size-2x);
    }
  }

  .nd-custom-select {
    &__control,
    &__value-container {
      transition: .2s;
      height: var(--height);
    }

    &__control {
      flex-wrap: nowrap;
    }

    &__single-value {
      gap: var(--nd-size-2x);
    }

    &__menu {
      padding: 0;
      min-width: 272px;
    }

    &__option::after {
      display: none;
    }
  }
}
