@import "../../mixins.scss";

.new-radio {
  --background-color: var(--control-minor);
  --size: 24px;

  display: block;
  height: var(--size);
  width: var(--size);
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  background-color: var(--background-color);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;

  &__checked-icon {
    display: none;
  }

  &_size_medium {
    --size: 32px;
  }

  &:hover {
    @include hover(var(--background-color));
  }
  &:active {
    @include pressed(var(--background-color));
  }
  &:focus {
    @include focus;
  }
  &:has(input:checked) {
    --background-color: var(--control-main);
  }
  &:has(input:disabled) {
    @include disabled;
  }

  input:checked ~ &__checked-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: var(--size);
    width: var(--size);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}