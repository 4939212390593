.export-scenarios-modal {
  width: 450px;

  &__loading-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-2x);
    text-align: center;
  }

  &__done-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-2x);
    align-items: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--nd-size-2x);
    align-items: flex-start;
    width: 100%;
  }

  &__success-button {
    align-self: flex-start;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: var(--nd-size-2x);
    background: var(--background-light-primary);
    border: none;
    cursor: pointer;
  }

  &__download-button {
    align-self: stretch;
  }

  ul {
    margin: 0;
  }
}