.audio {
  display: flex;
  position: relative;
  &__wrapper {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  &__progress-background {
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }

  &__progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }
}