.sidebar-header {
  display: flex;
  gap: var(--nd-size-2x);
  align-items: center;
  overflow: hidden;
  direction: ltr;
  justify-content: center;
  height: var(--header-height);
  padding: var(--nd-size-4x);
  box-sizing: border-box;
  color: black;
  width: var(--nd-sidebar-folded-width);
  margin-right: auto;

  &__logo svg {
    transition: .2s;
    margin-right: auto;
  }

  &__text {
    margin-top: 12px;
  }
}

.sidebar_expanded .sidebar-header {
  justify-content: flex-start;
  width: initial;
  padding-left: var(--nd-size-4x);
}
