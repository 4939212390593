.create-project-modal {
  .nd-side-modal__content {
    padding: 16px;
    gap: 16px;
  }

  &__error {
    color: var(--error);
    font: var(--caption-1);
    margin-left: var(--nd-size-4x);
  }
  
  &__selectors,
  &__checkboxes {
    display: flex;
    gap: 16px;
  }

  &__chatterbox,
  &__async {
    display: flex;
    align-items: center;
    gap: var(--nd-size-2x);
    margin-left: 16px;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--background-light-primary);
    box-shadow: var(--nd-popup-shadow);
    padding: var(--nd-size-2x);
    border-top-right-radius: var(--nd-border-radius);
    border-top-left-radius: var(--nd-border-radius);
    > * {
      width: 100%;
    }
  }
}