.page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  padding: var(--nd-size-6x);
  box-sizing: border-box;
  min-height: 100vh;
  max-width: calc(100vw - var(--nd-sidebar-unfolded-width));
  transition: .3s;
  overflow-y: auto;
  background-color: var(--background-light-primary);

  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.page-container-wide {
  max-width: calc(100vw - var(--nd-sidebar-folded-width));
}