.notifications-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0;
  isolation: isolate;
  position: relative;
  padding-bottom: 12px;

  &__close-button-wrapper {
    position: absolute;
    bottom: 12px;
    left: -64px;
    padding-right: 8px;
    padding-bottom: 4px;
    overflow: hidden;
  }

  &__close-button {
    margin-left: auto;
    height: 56px;
    transition: bottom .2s;
    padding: 0;
    position: relative;
    bottom: -64px;
  }

  &:hover &__close-button {
    bottom: 0;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &:hover &__list >* {
    margin-top: 0 !important;
    transform: scale(1) translateY(0) !important;
    filter: brightness(1) !important;
    opacity: 1 !important;
  }

  &__list >* {
    z-index: 1;
    transition: margin-top .4s, transform .2s, filter .2s;
  }

  &__list >*:nth-last-child(2) {
    transform: scale(0.85) translateY(8px);
    transform-origin: 50% 100%;
    filter: contrast(90%) brightness(.96);
  }
  &__list >*:nth-last-child(n+3) {
    transform: scale(0.75) translateY(16px);
    transform-origin: 50% 100%;
    filter: contrast(80%) brightness(.93);
  }
}
