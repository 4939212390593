.sai-notification {
  --background-color: var(--accent-light-green);

  display: grid;
  background-color: var(--background-color);
  border-radius: 16px;
  overflow: hidden;
  width: 360px;
  min-height: 56px;
  color: var(--text-icons-light-primary-inverted);
  animation: notificationFadeIn ease-in-out 200ms;

  grid-template-columns: 56px 1fr 56px;
  grid-template-areas: 'icon text close';

  &_mode_link,
  &_mode_button {
    grid-template-columns: 56px 1fr auto 56px;
    grid-template-areas: 'icon text action close';
  }

  &_mode_button-footer {
    grid-template-columns: 56px 1fr 56px;
    grid-template-rows: 56px 56px;
    grid-template-areas: "icon text close"
                         "action action action"
    ;
  }

  &_type_info {
    --background-color: var(--accent-light-dark);
  }
  &_type_success {
    --background-color: var(--accent-light-green);
  }
  &_type_error {
    --background-color: var(--accent-light-red);
  }

  &__icon,
  &__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    grid-area: icon;
  }

  &__text {
    grid-area: text;
    overflow: hidden;
    padding: var(--nd-size-2x) 0;
    cursor: pointer;
  }

  &__text-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;

    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__close-button {
    grid-area: close;
    opacity: .5;

    .icon {
      color: var(--text-icons-light-primary-inverted);
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: action;

    .nd-button {
      color: var(--text-icons-light-primary-inverted);
      opacity: .7;
    }
  }

  &_expanded {
    grid-template-areas: 
      'icon action action close'
      'text text text text'
    ;
  }

  &_expanded &__text-wrapper {
    padding: var(--nd-size-2x) var(--nd-size-4x);
    margin-top: calc(-1 * var(--nd-size-2x));
    word-break: break-word;
    -webkit-line-clamp: initial;
    line-clamp: initial;
  }
}

@keyframes notificationFadeIn {
  from {
    transform: translateY(10px) scale(0.7);
    filter: opacity(50%);
  }
  to {
    transform: translateY(0) scale(1);
    filter: opacity(100%);
  }
}

@keyframes notificationFadeOut {
  from {
    transform: translateY(0) scale(1);
    filter: opacity(100%);
  }
  to {
    transform: translateY(10px) scale(0.7);
    filter: opacity(0);
  }
}