.reference-link {
  display: flex;
  align-items: center;
  gap: var(--nd-size-base);
  text-decoration: none;
  color: var(--text-minor);

  font: var(--caption-1);

  &:hover {
    text-decoration: underline;
  }
}