@import "../../../mixins.scss";

.sidebar-item {
  --icon-width: 64px;
  --navigation-helper-width: 220px;

  position: relative;
  display: flex;
  align-items: center;
  padding: var(--nd-size-base) 0;
  box-sizing: border-box;
  text-decoration: none;
  color: initial;
  width: var(--nd-sidebar-unfolded-width);
  direction: ltr;
  max-width: 100%;
  cursor: pointer;
  min-width: var(--icon-width);
  border: none; 
  outline: none;
  font: var(--body-2);
  background: transparent;
  height: var(--item-height);
  flex-shrink: 0;
  z-index: 1;

  &:hover {
    @include hover(var(--day-background-minor));
  }

  &_active {
    position: relative;
    background-color: var(--background-light-primary);

    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 100%;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 2px 0px 0px 2px;
    }
  }

  &_active &__icon {
    opacity: 1;
  }

  &_opened &__expand-icon-wrapper {
    transform: rotate(180deg);
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
    width: var(--icon-width);
    flex-shrink: 0;
  }
  &__icon {
    opacity: .5;
  }

  &_child {
    padding-left: var(--icon-width);
  }

  &__expand-icon-wrapper {
    margin-left: auto;
    transition: .2s;
  }

  &__navigation-helper {
    display: none;
    position: fixed;
    left: var(--nd-sidebar-folded-width);
    width: var(--navigation-helper-width);
    background-color: var(--day-background-minor);
    flex-direction: column;
    border-radius: var(--nd-border-radius);
    box-sizing: border-box;
    box-shadow: var(--nd-base-shadow);
    overflow: hidden;
  }

  &__navigation-helper-item {
    display: flex;
    padding: var(--nd-size-5x) var(--nd-size-4x);
    box-sizing: border-box;
    min-height: 48px;
    align-items: center;
    text-decoration: none;
    color: var(--text-icons-light-primary);

    &:hover {
      @include hover(var(--day-background-minor));
    }
    &:focus {
      @include pressed(var(--day-background-minor));
    }
  }

  &:hover &__navigation-helper {
    display: flex;
  }
}

.sidebar_expanded .sidebar-item {
  padding-right: var(--nd-size-3x);
  min-width: 220px;
}