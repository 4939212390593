.no-projects-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;

  &__message-container {
    width: 80%;
    max-width: 500px;
  }
}