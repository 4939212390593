.nd-custom-select__multi-value-floating {
  display: inline-block;
  max-width: 100%;
  background-color: var(--control-minor);
  border-radius: 8px;
  padding: 0 4px 0 10px;
  height: max-content;
  margin-right: 4px;
  position: relative;
  z-index: 1;

  &:not(:last-of-type) {
    margin-right: 4px;
  }

  .nd-custom-select__multi-value-remove-button {
    margin: 0 var(--nd-size-base);
    border: none;
    border-radius: var(--nd-size-base);
    padding: 0 var(--nd-size-base);
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, .2);
    }
  }
}
