.manage-model-slots-modal {
  &__content {
    box-sizing: border-box;
    padding: var(--nd-size-4x);;
  }

  &__actions {
    min-width: 0;
    flex: 0 0 60px;
    display: flex;
    justify-content: flex-end;
  }

  .nd-table__row &__actions>* {
    visibility: hidden;
  }

  .nd-table__row:hover &__actions>* {
    visibility: visible;
  }
}