.audio-list {
  width: 100%;
  &__wrapper {
    max-height: 300px;
    overflow-y: auto;
  }
  &__button {
    .icon-wrapper {
      width: 54px !important;
    }
  }
}