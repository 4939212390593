.nd-add-example-modal {
  width: 450px;

  &__content {
    padding: var(--nd-size-2x);
    box-sizing: border-box;
  }

  &__warning {
    color: #FF4930;
    font: var(--body-2);
  }

  textarea {
    max-height: 250px;
    overflow-y: scroll;
  }

  .nd-modal__close-button {
    z-index: 1;
  }

  .modal-body > * {
    margin-bottom: var(--nd-size-2x);
  }
}