.modal-launch-clustering {
  &__header {
    box-shadow: var(--nd-base-shadow);
  }

  &__content {
    padding: var(--nd-size-4x);

    .nd-custom-select__control {
      background-color: rgba(92, 90, 87, 0.1);
    }
  }

  &__footer {
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.12);;
    border-radius: var(--nd-size-6x);
    padding: var(--nd-size-2x);

    .nd-button {
      flex: 1 1 0;
      min-height: 56px;
    }
  }
}