@import "../../mixins.scss";

.nd-input {
  position: relative;
  display: flex;
  gap: 4px;
  box-sizing: border-box;
  align-items: center;

  --horizontal-spacing: var(--nd-size-4x);
  --min-height: var(--control-height-s);
  --border-radius: 16px;
  --background-color: var(--control-minor);
  --icon-size: 24px;
  --font: var(--body-2);

  padding-left: var(--horizontal-spacing);
  padding-right: var(--horizontal-spacing);

  min-height: var(--min-height);

  border-radius: var(--border-radius);

  background-color: var(--background-color);

  &_fluid {
    width: 100%;

    input {
      width: 100%;
    }
  }

  &_error {
    outline-color: var(--error) !important;
  }

  &_disabled {
    @include disabled;
  }

  &__icon,
  &__clear-button,
  &__error-icon {
    display: flex;
    align-items: center;
    min-width: var(--icon-size);
  }

  &__icon {
    order: 1;
    margin-left: calc(-0.2 * var(--horizontal-spacing));

    &_position_right {
      order: 4;
      margin-left: initial;
      margin-right: calc(-0.2 * var(--horizontal-spacing));
    }
  }

  &__input-wrapper {
    order: 2;
    flex: 1 1 0;

    display: flex;
    flex-direction: column;

    overflow: hidden;
    white-space: nowrap;

    input {
      box-sizing: border-box;
      width: 100%;  
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      font: var(--font);
    }
  }

  &__clear-button {
    order: 3;
    display: none;
  }
  &:hover &__clear-button {
    display: flex;
  }

  &__error-icon {
    order: 5;
  }

  &_size_tiny {
    --min-height: var(--control-height-xs);
    --border-radius: 10px;
    --font: var(--caption-1);
  }

  &_size_small {
    --min-height: var(--control-height-s);
    --border-radius: 12px;
  }

  &_size_medium {
    --min-height: var(--control-height-m);
    --border-radius: var(--control-border-radius-m);
  }

  &_size_large {
    --min-height: var(--control-height-l);
  }

  &_visual-style_default {
    --background-color: var(--control-minor);
  }

  &_visual-style_contrast,
  &_visual-style_underline {
    --background-color: var(--forced-white);
  }

  &_visual-style_underline {
    --border-radius: 0;
    border-bottom: 1px solid var(--line);
    outline-color: transparent !important;
    padding-left: 0;

    &:hover {
      box-shadow: 0 1px var(--text-icons-light-primary);
    }
  }

  &:hover,
  &:focus,
  &:has(input:focus) {
    @include focus;
  }

  &__placeholder {
    color: var(--text-minor);
    font: var(--body-2);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &_floating input {
    &::placeholder {
      color: transparent;
    }
  }

  &_floating input {
    max-height: 0;
    transition: max-height .2s;
  }

  &_floating &__placeholder {
    transition: font-size .2s;
  }

  &_floating:has(input:focus) input,
  &_floating.nd-input_with-value input {
    max-height: var(--min-height);
  }

  &_floating.nd-input_with-value &__placeholder,
  &_floating:has(input:focus) &__placeholder {
    font: var(--caption-1);
  }

  &_shift-spacing {
    margin-left: calc(-1 * var(--horizontal-spacing));
  }
}