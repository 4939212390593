.toggle-container {
  --toggle-container-height: 32px;
  --toggle-container-width: 52px;
  --toggle-container-padding: 2px;
  --toggle-size: calc(var(--toggle-container-height) - (2 * var(--toggle-container-padding)));


  display: block;
  position: relative;
  width: var(--toggle-container-width);
  height: var(--toggle-container-height);
  cursor: pointer;

  &_size_small {
    --toggle-container-height: 26px;
    --toggle-container-width: 42px;
    --toggle-container-padding: 2px;
  }

  &__input {
    visibility: hidden;
  }

  &__field {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background-color: var(--control-minor);

    &::before {
      content: ' ';
      width: var(--toggle-size);
      height: var(--toggle-size);
      top: var(--toggle-container-padding);
      left: var(--toggle-container-padding);
      border-radius: 50%;
      background-color: var(--forced-white);
      position: absolute;
      transition: left .2s;
      filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.10));
    }
  }
  & input:checked ~ &__field {
    background-color: var(--control-main);
  }
  & input:checked ~ &__field::before {
    left: calc(var(--toggle-container-width) - var(--toggle-size) - var(--toggle-container-padding));
  }
  & input:disabled ~ &__field {
    opacity: 0.5;
  }
}