@import "../../mixins.scss";

.nd-time-input {
  max-width: 75px;
  min-width: 75px;
  text-align: center;

  background: var(--day-background-minor);
  border: none;
  border-radius: var(--control-border-radius-m);
  padding: var(--nd-paddings);
  display: flex;

  &_disabled {
    cursor: default;
    opacity: 0.6;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    @include focus;
  }

  &:disabled {
    @include disabled;
  }

  &::placeholder {
    color: var(--text-minor);
  }

  &_contrast {
    background-color: var(--background-light-secondary);
  }

  &_size_small {
    border-radius: var(--nd-border-radius-large);
    padding: 9px 12px;
  }

  &_size_large {
    padding: var(--nd-size-4x);
    border-radius: var(--nd-border-radius-large);
  }

  &_transparent {
    &,
    &:hover {
      &:not(:focus) {
        border-color: transparent;
        box-shadow: none;
      }
      background: transparent;
    }
  }

  &_no-paddings {
    padding: 0;
  }

  &_search {
    padding-left: 48px;
  }
}

.range-time-input {
  --background-color: var(--control-minor);

  border-radius: var(--control-border-radius-m);
  min-height: var(--control-height-m);
  background-color: var(--background-color);
  width: fit-content;

  &_fluid {
    width: 100%;
  }

  display: grid;
  grid-template-areas: 
    'placeholder icon'
    'value icon';
  grid-template-columns: 1fr 24px;
  grid-template-rows: 0.875rem 1fr;
  height: var(--control-height-m);
  padding: 0 var(--nd-size-4x);
  box-sizing: border-box;

  &__placeholder {
    display: flex;
    align-items: flex-end;
    grid-area: placeholder;
    font: var(--caption-1);
    color: var(--text-minor);
    margin-bottom: calc(-1 * var(--nd-size-base));
  }

  &__value-wrapper {
    grid-area: value;
    display: flex;
    align-items: center;
    gap: var(--nd-size-base);
  }

  &__icon {
    grid-area: icon;
  }

  &:hover,
  &:has(input:focus) {
    @include focus;
  }

  input {
    border: none;
    background-color: transparent;
    outline: none;
    font: var(--body-2);
    min-width: 46px;
    padding: 0;
    margin: 0;

    &:focus {
      outline: none;
    }
  }
}
