.modal-overlay {
  position: fixed;
  top: 0;
  background-color: rgba(0,0,0,0.1);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}