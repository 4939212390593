.export-call-statistics-modal {
  &__actions {
    display: flex;
    gap: var(--nd-size-2x);
  }

  &__action {
    padding: 0 var(--nd-size-4x);
  }

  &__filters {
    display: flex;
    width: 100%;

    > * {
      display: flex;
      flex-direction: column;

      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  &__label {
    color: var(--text-minor);
  }
}
