@import "../../mixins.scss";

.nd-button {
  --background-color: var(--control-minor);
  --border-radius: var(--nd-size-4x);

  --vertical-spacing: var(--nd-size-2x);
  --horizontal-spacing: var(--nd-size-4x);

  box-sizing: border-box;
  background: var(--background-color);
  color: var(--text-icons-light-primary);
  font: var(--body-2);
  min-height: 48px;
  min-width: 48px;

  padding-top: var(--vertical-spacing);
  padding-bottom: var(--vertical-spacing);
  padding-left: var(--horizontal-spacing);
  padding-right: var(--horizontal-spacing);

  border: none;
  cursor: pointer;
  transition: background 0.1s;
  gap: var(--nd-size-base);
  border-radius: var(--border-radius);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &:hover {
    @include hover(var(--background-color));
  }

  &:active {
    @include pressed(var(--background-color));
  }
  &:focus {
    @include focus;
  }

  &_visual-style_minor {
    --background-color: var(--control-minor);
  }
  &_visual-style_main {
    --background-color: var(--control-main);
    font-weight: var(--font-weight-medium);
  }
  &_visual-style_outline {
    border: 1px solid var(--line);
    --background-color: var(--background-light-primary);
  }
  &_visual-style_floating {
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.12);
    --background-color: var(--background-floating);
  }
  &_visual-style_ghost {
    --background-color: transparent;
  }
  &_visual-style_floating {
    --background-color: var(--background-floating);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  &_visual-style_destructive {
    --background-color: var(--accent-light-red);
    color: var(--text-icons-light-primary-inverted);
  }

  &_pressed {
    @include pressed(var(--background-color));
  }

  &_form_squircle.nd-button_size_small {
    --border-radius: var(--nd-size-3x);
  }
  &_form_squircle.nd-button_size_mini {
    --border-radius: var(--nd-size-2_5x);
  }

  &_size_large {
    min-height: 56px;
    min-width: 56px;
  }
  &_size_small {
    min-height: 40px;
    min-width: 40px;
  }
  &_size_mini {
    --horizontal-spacing: var(--nd-size-3x);
    min-height: 32px;
    min-width: 32px;
    font: var(--caption-2);
  }

  & > svg {
    display: block;
  }

  & > .icon:not(:last-child) {
    margin-top: -2px;
    margin-bottom: -2px;
  }

  &_fluid {
    flex: 1 1 0;
  }

  &_form_circle {
    --border-radius: 100px;
  }

  &_only-icon {
    --vertical-spacing: var(--nd-size-base);
    --horizontal-spacing: var(--vertical-spacing);
    justify-content: center;
    align-items: center;
  }

  &_disabled {
    @include disabled;
  }
}