.nd-message {
  background: var(--control-minor);
  border-radius: var(--nd-border-radius-large);
  padding: var(--nd-size-4x);
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: var(--nd-size-5x);
  font: var(--caption-1);
  color: var(--text-icons-light-tertiary);

  &_error {
    color: var(--accent-light-red);
  }
}
